import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class SpecialsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.ariasalons.net/specials/"}
        />
        <Grid
          className={"custom-page-specials custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"sbjplvtrcagspzfc"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column
              className={"xyjngpqdxrrnkkyp"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "6ef62dc3-f852-4a38-8fb7-bc9fb4730a6e",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ptafgrkfjohfxhvm"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"qpunepcpblyytrto"}
              style={{ padding: 16 }}
              width={8}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "2c33b76f-f6b1-4e84-b441-7ee9dced5edd",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"isacevihaikihzwn"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"prokboacdazwmfxs"}
              style={{ padding: 16 }}
              width={6}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "1976bf47-514d-4231-9337-6f517fb20cf5",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 28989 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
